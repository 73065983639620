// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, PaymentMethod, Project} from '@supermove/models';

// App
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import useBeginPaymentV3 from '@shared/modules/Payment/hooks/useBeginPaymentV3';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPaymentWithDescriptionSection from 'modules/Project/Billing/Payment/New/Method/Check/components/ProjectBillingPaymentWithDescriptionSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const DefaultPaymentPageContent = ({job, paymentMethod}: any) => {
  const {params, navigator} = useNavigation();
  const paymentAmount = _.toNumber(params.paymentAmount);
  const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(paymentMethod, {
    amount: paymentAmount,
  });
  const amount = paymentMethod ? paymentAmount + paymentFeeAmount : paymentAmount;
  // @ts-expect-error TS(2345): Argument of type '{ billId: any; customerId: any; ... Remove this comment to see the full error message
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount,
    method: params.paymentMethod,
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
    paymentMethodId: paymentMethod?.id,
    paymentFeeAmount,
  });
  const {form, handleSubmit, submitting} = useBeginPaymentV3({
    jobId: job.id,
    beginPaymentV3Form,
    onSuccess: ({payment}: any) =>
      Navigation.navigateFromPaymentSuccess({navigator, params, payment}),
    onError: (errors: any) => console.log({errors}),
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  const inputPlaceholder =
    params.paymentMethod === PaymentMethodKind.CHECK ? 'Last 4 digits' : 'Enter any notes';

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? undefined : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ScrollView>
          <Space height={20} />
          <ProjectBillingPaymentWithDescriptionSection
            form={form}
            handleSubmit={handleSubmit}
            submitting={submitting}
            descriptionField={'beginPaymentV3Form.description'}
            amountField={'beginPaymentV3Form.amount'}
            descriptionInputPlaceholder={inputPlaceholder}
            button={'Paid'}
            pageTitle={'Please pay the amount in full'}
            pageSubtitle={'When you are ready, the crew will handle your payment.'}
          />
          <Space height={20} />
        </ScrollView>
      </Container>
      {isMainFlow && (
        <PageFooter>
          <PageFooter.Row>
            <PageFooter.BackButton />
          </PageFooter.Row>
        </PageFooter>
      )}
    </Container>
  );
};

const DefaultPaymentPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(DefaultPaymentPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
      paymentMethodUuid: params.paymentMethodUuid || '',
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <DefaultPaymentPageContent
            job={data.job}
            refetch={refetch}
            paymentMethod={data.paymentMethodByUuid}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DefaultPaymentPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  ${Project.getName.fragment}

  query DefaultPaymentPage($jobUuid: String!, $paymentMethodUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        currentPrimaryBill {
          id
          customerId
        }
        ...Project_getName
      }
      ...Job_getFullName
      ...Job_getTipName
    }
    paymentMethodByUuid(paymentMethodUuid: $paymentMethodUuid) {
      id
      ...PaymentMethod_computePaymentFeeAmount
    }
  }
`;

export default DefaultPaymentPage;
