// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Job, JobModel} from '@supermove/models';

// App
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';

const BillableTimesheetIncompleteWarningModal = ({
  job,
  isOpen,
  handleClose,
}: {
  job: JobModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {isMissingTimesheetBlock} = Job.getTimesheetMissingRequirements(job, {
    isCompletionRequired: true,
  });

  return (
    <WarningModal
      isOpen={isOpen}
      title={'Unable to proceed'}
      message={
        isMissingTimesheetBlock
          ? 'Please enter at least one time block.'
          : 'Please enter an end time or remove the last time block.'
      }
      handlePressOutside={handleClose}
      handlePrimaryAction={handleClose}
      primaryActionText={'Close'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillableTimesheetIncompleteWarningModal.fragment = gql`
  ${Job.getTimesheetMissingRequirements.fragment}

  fragment BillableTimesheetIncompleteWarningModal on Job {
    id
    ...Job_getTimesheetMissingRequirements
  }
`;

export default BillableTimesheetIncompleteWarningModal;
