// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import useBeginPaymentV3 from '@shared/modules/Payment/hooks/useBeginPaymentV3';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingTipAmountSection from 'modules/Project/Billing/Payment/New/Tip/components/ProjectBillingTipAmountSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const TipNewPaymentBillingProjectJobContent = ({job, paymentMethod}: any) => {
  const {params, navigator} = useNavigation();
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    name: params.paymentName,
    amount: params.paymentAmount,
    jobId: job.id,
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
    tipAmount: '',
  });
  const {form} = useBeginPaymentV3({beginPaymentV3Form});
  const paymentAmountField = 'beginPaymentV3Form.amount';
  const paymentNameField = 'beginPaymentV3Form.name';
  const tipAmountField = 'beginPaymentV3Form.billTipForm.amount';

  const handleNavigation = () => {
    const paymentAmount = Currency.toMutation(_.get(form.values, paymentAmountField));
    const tipAmount = Currency.toMutation(_.get(form.values, tipAmountField));
    const updatedPaymentAmount = tipAmount
      ? Currency.toForm(paymentAmount + tipAmount)
      : Currency.toForm(paymentAmount);
    navigator.navigate(params.paymentMethodScreen, {
      jobUuid: params.jobUuid,
      paymentName: form.values.beginPaymentV3Form.name,
      // The payment fee isn't applied until the step where we submit the payment.
      // Here, the payment amount is only the sum of the payment amount and tip amount.
      paymentAmount: Currency.toMutation(updatedPaymentAmount),
      paymentMethod: params.paymentMethod,
      paymentMethodUuid: params.paymentMethodUuid,
      jobId: (form.values.beginPaymentV3Form as any).jobId,
      tipAmount,
      step: params.step,
    });
  };

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? undefined : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <ScrollView>
        <Space height={24} />
        <ProjectBillingTipAmountSection
          project={job.project}
          form={form}
          paymentAmountField={paymentAmountField}
          paymentNameField={paymentNameField}
          tipAmountField={tipAmountField}
          paymentMethod={paymentMethod}
        />
      </ScrollView>
      <PageFooter>
        <PageFooter.Row style={{justifyContent: 'space-between'}}>
          {isMainFlow ? <PageFooter.BackButton /> : <Space />}
          <PageFooter.Button onPress={handleNavigation} color={colors.blue.interactive}>
            Submit
          </PageFooter.Button>
        </PageFooter.Row>
      </PageFooter>
    </Container>
  );
};

const TipNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(TipNewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
      paymentMethodUuid: params.paymentMethodUuid || '',
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <TipNewPaymentBillingProjectJobContent
            job={data.job}
            refetch={refetch}
            paymentMethod={data.paymentMethodByUuid}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TipNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingTipAmountSection.fragment}

  query TipNewPaymentBillingProjectJobPage($jobUuid: String!, $paymentMethodUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
        ...ProjectBillingTipAmountSection
      }
      ...Job_getFullName
    }
    paymentMethodByUuid(paymentMethodUuid: $paymentMethodUuid) {
      id
      ...ProjectBillingTipAmountSection_PaymentMethod
    }
  }
`;

export default TipNewPaymentBillingProjectJobPage;
