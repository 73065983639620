/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Checkbox, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  background-color: ${(props) => ((props as any).isSelected ? colors.blue.accent : colors.white)};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Touchable = Styled.Touchable`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Details = Styled.H7`
  margin-left: 10px;
  color: ${colors.gray.primary};
  flex: 1;
`;

const Space = Styled.View`
  width: 40px;
`;

const TruckItem = ({isFirst, isSelected, truck, onSelect}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container isSelected={isSelected}>
      {isFirst && <Line />}
      <Content>
        <Touchable onPress={() => onSelect(truck)}>
          <Name>{truck.name}</Name>
          {!!truck.size && <Details>{`(${truck.size})`}</Details>}
        </Touchable>
        <Space />
        <Checkbox
          checked={isSelected}
          size={35}
          iconSize={20}
          color={colors.blue.interactive}
          onChange={() => onSelect(truck)}
        />
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TruckItem.fragment = gql`
  fragment TruckItem on Truck {
    id
    name
    size
  }
`;

export default TruckItem;
