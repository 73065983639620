// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useIsFocused, useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPaymentMethodsSection from 'modules/Project/Billing/Payment/New/components/ProjectBillingPaymentMethodsSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const PaymentBillingProjectJobContent = ({job}: any) => {
  const {params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? undefined : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <ProjectBillingPaymentMethodsSection
        project={job.project}
        paymentName={Job.getPaymentName(job)}
        jobId={job.id}
      />
      {isMainFlow && (
        <PageFooter>
          <PageFooter.Row>
            <PageFooter.BackButton />
          </PageFooter.Row>
        </PageFooter>
      )}
    </Container>
  );
};

const NewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(NewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  // When the page is re-navigated to, we need to reload the content to get the new balance.
  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (data ? <PaymentBillingProjectJobContent job={data.job} refetch={refetch} /> : null)}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getPaymentName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingPaymentMethodsSection.fragment}

  query NewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
        ...ProjectBillingPaymentMethodsSection
      }
      ...Job_getFullName
      ...Job_getPaymentName
    }
  }
`;

export default NewPaymentBillingProjectJobPage;
